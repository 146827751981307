<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <div class="m-2">

      <!-- Table Top -->
      <b-row>
        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('SEARCH')"
            />
          </div>
        </b-col>
        <!-- Habit-->
        <b-col
          v-if="subTaskID==0"
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <v-select
              v-model="habit"
              :options="allHabits"
              label="taskbeskrivning"
              class="flex-grow-1 ml-50 mr-1"
              placeholder="Select Habit"
              @input="setMediaHabitUrl()"
            />
          </div>
        </b-col>
        <!-- Behaviour-->
        <b-col
          v-show="taskID==0"
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <v-select
              v-model="behaviour"
              :options="allBehaviours"
              label="subtaskstring"
              class="flex-grow-1 ml-50 mr-1"
              placeholder="Select Behaviour"
              @input="setMediaBehaviourUrl()"
            />
          </div>
        </b-col>
        <b-col
          v-show="media.newmediapath"
          md="12"
          cols="12"
          class="mb-2 mt-2"
        >
          <!-- post video -->
          <b-embed
            type="iframe"
            :src="media.newmediapath"
            allowfullscreen
            class="rounded mb-50"
          />
          <div class="icon-with-text mt-2">
            <span class="text  mr-1">Media : {{ media.header }} ({{ media.mediaID }})</span>
            <feather-icon
              icon="Trash2Icon"
              class="cursor-pointer"
              size="24"
              @click="updateMedia(0)"
            />
          </div>
        </b-col>
      </b-row>

    </div>
    <b-table
      ref="refMediaListTable"
      :items="allMedias"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
      <!-- Column: mediaID -->
      <template #cell(ID)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.mediaID }}
          </span>
        </b-media>
      </template>
      <!-- Column:Header -->
      <template #cell(Header)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.header }}
          </span>
        </b-media>
      </template>
      <!-- Column:Url -->
      <template #cell(Url)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ strSubstring(data.item.newmediapath,20) }}
          </span>
        </b-media>
      </template>
      <!-- Column:Scope -->
      <template #cell(scope)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.scope === 1 ? 'PRIVATE' : 'PUBLIC' }}
          </span>
        </b-media>
      </template>
      <!-- Column:Language -->
      <template #cell(Language)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ resolveLanguage(data.item.lang) }}
          </span>
        </b-media>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon
            :id="`game-row-${data.item.mediaID}-send-icon`"
            icon="PlusIcon"
            class="cursor-pointer mr-1"
            size="16"
            @click="updateMedia(data.item.mediaID)"
          />
          <feather-icon
            :id="`game-row-${data.item.ID}-send-icon`"
            icon="VideoIcon"
            class="cursor-pointer mr-1"
            size="16"
            @click="openMediaModel(data.item)"
          />
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('SHOWING') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('ENTRIES') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalMedias"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <media-model
      :view-media="viewMedia"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BRow, BCol, BTable,
  BMedia, BFormInput,
  BPagination, VBPopover, BEmbed,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from 'vuex'
import { ref, computed } from '@vue/composition-api'
import { mixinList } from '@/constants/mixinValidations'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mixinDate } from '@/constants/mixinDate'
import constants from '@/constants/static.json'
import MediaModel from '../media-list/MediaModel.vue'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import router from '@/router'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BPagination,
    BFormInput,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    VBPopover,
    MediaModel,
    BEmbed,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  mixins: [mixinList, mixinDate],
  data() {
    return {
      viewMedia: {
        mediaID: 0,
        url: '',
        newmediapath: '',
        companyID: 0,
        header: '',
        description: '',
        lang: '',
        scope: '',
      },
      media: {
        mediaID: 0,
        header: '',
        url: '',
        newmediapath: '',
      },
      mediaUrl: '',
      competitionID: 0,
      templateID: 0,
      taskID: 0,
      subTaskID: 0,
      habit: '',
      behaviour: '',
      allHabits: [],
      allBehaviours: [],
      allMediasResult: [],
      mediaFullview: true,
    }
  },
  setup() {
    const tableColumns = [
      { key: 'ID', sortable: false },
      { key: 'Header', sortable: false },
      { key: 'Url', sortable: false },
      { key: 'Scope', sortable: false },
      { key: 'Language', sortable: false },
      { key: 'Actions' },
    ]

    const { PER_PAGE_OPTIONS, PER_PAGE } = constants
    const refMediaListTable = ref(null)

    const perPage = ref(PER_PAGE)
    const totalMedias = ref(0)
    const currentPage = ref(1)
    const perPageOptions = PER_PAGE_OPTIONS
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const statusFilter = ref('')

    const dataMeta = computed(() => {
      const localItemsCount = refMediaListTable.value ? refMediaListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalMedias.value,
      }
    })

    const refetchData = () => {
      refMediaListTable.value.refresh()
    }

    const {
      successMessage, errorMessage, showErrorMessage,
    } = mixinAlert()

    return {
      tableColumns,
      perPage,
      currentPage,
      totalMedias,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMediaListTable,
      statusFilter,
      refetchData,
      successMessage,
      errorMessage,
      showErrorMessage,
    }
  },
  mounted() {
    if (router.currentRoute.params.id) {
      if (router.currentRoute.query.objectID === 'game') {
        this.competitionID = router.currentRoute.params.id
        this.fetchActivities(this.competitionID)
      }
      if (router.currentRoute.query.objectID === 'template') {
        this.templateID = router.currentRoute.params.id
        this.fetchTemplatesTask(this.templateID)
      }
      this.taskID = router.currentRoute.query.taskID
      this.subTaskID = router.currentRoute.query.subTaskID
    }
  },
  created() {
    this.fetchMedias()
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapState('media', ['allMedias', 'mediaCount']),
    ...mapState('activity', ['allActivities']),
  },
  // eslint-disable-next-line vue/order-in-components
  watch: {
    currentPage: {
      handler() {
        this.fetchMedias()
      },
    },
  },
  methods: {
    ...mapActions('media', ['fetchAllMedias', 'deleteMedia']),
    ...mapActions('activity', ['updateActivity', 'updateSubActivity', 'fetchAllActivities']),
    ...mapActions('template', ['fetchAllTemplateTask', 'updateTask', 'updateSubTask']),
    resolveLanguage(data) {
      const language = constants.LANGUAGES.find(i => i.country_code === data)
      if (language) {
        return language.name
      }
      return ''
    },
    setMediaBehaviourUrl() {
      if (this.behaviour) {
        const mediaObject = this.allMediasResult.find(i => i.mediaID === this.behaviour.mediaID)
        if (mediaObject) {
          this.media = { ...mediaObject }
        } else {
          this.resetSetMedia()
          this.successMessage(this.$i18n.t('MESSAGE.NO_MEDIA_FOR_BEHAVIOUR'))
        }
      } else {
        this.resetSetMedia()
      }
    },
    setMediaHabitUrl() {
      if (this.habit) {
        const mediaObject = this.allMedias.find(i => i.mediaID === this.habit.mediaID)
        if (mediaObject) {
          this.media = { ...mediaObject }
        } else {
          this.resetSetMedia()
          this.successMessage(this.$i18n.t('MESSAGE.NO_MEDIA_FOR_HABIT'))
        }
      } else {
        this.resetSetMedia()
      }
    },
    resetSetMedia() {
      this.media.mediaID = 0
      this.media.url = ''
      this.media.newmediapath = ''
    },
    updateMedia(id) {
      let payload = { }
      if ((this.taskID > 0) && (!this.habit)) {
        this.errorMessage('Please select the habit related to the media file ')
        return
      }
      if ((this.subTaskID > 0) && (!this.behaviour)) {
        this.errorMessage('Please select the behaviour related to the media file ')
        return
      }
      if (router.currentRoute.query.objectID === 'template') {
        if (this.habit.taskID) {
          payload = { ...this.habit }
          payload.mediaID = id
          this.habit.mediaID = id
          this.updateTask(payload).then(response => {
            if (response) {
              this.successMessage(this.$i18n.t('MESSAGE.MEDIA_UPDATED'))
              this.setMediaHabitUrl()
            }
          }).catch(() => {
            this.showErrorMessage()
          })
        }
        if (this.behaviour.subtaskID) {
          payload = { ...this.behaviour }
          payload.mediaID = id
          this.behaviour.mediaID = id
          this.updateSubTask(payload).then(response => {
            if (response) {
              this.successMessage(this.$i18n.t('MESSAGE.MEDIA_UPDATED'))
              this.setMediaBehaviourUrl()
            }
          }).catch(() => {
            this.showErrorMessage()
          })
        }
      }
      if (router.currentRoute.query.objectID === 'game') {
        if (this.habit.taskID) {
          payload = { ...this.habit }
          payload.mediaID = id
          this.habit.mediaID = id
          this.updateActivity({ task: payload }).then(response => {
            if (response) {
              this.successMessage(this.$i18n.t('MESSAGE.MEDIA_UPDATED'))
              this.setMediaHabitUrl()
            }
          }).catch(() => {
            this.showErrorMessage()
          })
        }
        if (this.behaviour.subtaskID) {
          payload = { ...this.behaviour }
          payload.mediaID = id
          this.behaviour.mediaID = id
          this.updateSubActivity({ subTask: payload }).then(response => {
            if (response) {
              this.successMessage(this.$i18n.t('MESSAGE.MEDIA_UPDATED'))
              this.setMediaBehaviourUrl()
            }
          }).catch(() => {
            this.showErrorMessage()
          })
        }
      }
    },
    openMediaModel(data) {
      if (data) {
        this.viewMedia = { ...data }
        this.$bvModal.show('media-modal')
      }
    },
    fetchActivities(id) {
      try {
        this.fetchAllActivities(id).then(() => {
          this.allActivities.forEach(e => {
            this.allHabits.push(e.task)
            e.task.task_subtasks.forEach(subTask => {
              this.allBehaviours.push(subTask.subtask)
            })
          })
        })
      } catch (error) {
        this.showErrorMessage()
      }
    },
    fetchTemplatesTask(id) {
      this.fetchAllTemplateTask(id).then(response => {
        if (response) {
          this.allHabits = response
          this.allHabits.forEach(e => {
            e.template_task_subtasks.forEach(subTask => {
              this.allBehaviours.push(subTask.template_subtask)
            })
          })
        }
      }).catch(() => {
        this.showErrorMessage()
      })
    },
    fetchMedias() {
      const payload = {
        q: this.searchQuery,
        perPage: this.perPage,
        page: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        status: this.statusFilter,
      }
      try {
        this.fetchAllMedias(payload).then(response => {
          this.totalMedias = this.mediaCount
          this.allMediasResult = response.allResult
        })
      } catch (error) {
        this.showErrorMessage()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
