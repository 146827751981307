<template>
  <b-modal
    id="media-modal"
    ref="m-modal"
    :title="`Media - ${media.header}`"
    cancel-variant="outline-secondary"
  >
    <b-form
      ref="form"
    >
      <b-row>
        <b-col cols="12">
          <!-- post video -->
          <b-embed
            type="iframe"
            :src="media.newmediapath"
            allowfullscreen
            class="rounded mb-50"
          />
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import {
  BForm, BModal, VBModal, BRow, BCol, BEmbed,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'

export default {
  components: {
    BForm,
    BModal,
    BRow,
    BCol,
    BEmbed,
  },
  directives: {
    'm-modal': VBModal,
    Ripple,
  },
  model: {
    prop: 'viewMedia',
  },
  props: {
    viewMedia: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      media: {
        mediaID: 0,
        url: '',
        newmediapath: '',
        companyID: 0,
        header: '',
        description: '',
        lang: '',
        scope: '',
      },
    }
  },
  watch: {
    viewMedia: {
      handler() {
        if (this.viewMedia) {
          this.media = this.viewMedia
        }
      },
    },
  },
  setup() {
    const {
      successMessage, errorMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
    }
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
